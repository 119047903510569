/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)
require('../css/app.scss');

// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
const $ = require('jquery');
window.jQuery = $;
window.$ = $;
global.$ = $;

//require('./bootstrap.bundle.min.js');
//var dt = require( 'datatables.net-bs4' );

//var dt = require('datatables.net');
//require('datatables.net-bs4')(window, $);

import 'datatables.net';
import dt from 'datatables.net-bs4';
dt(window, $);

//require('datatables.net-responsive')(window);
//require('datatables.net-buttons')(window);


//require('datatables.net')( window, $ );
//require('datatables.net-bs4')( window, $ );
//$.fn.dataTable = $.fn.DataTable = global.DataTable = require('datatables.net-bs4');

//require('datatables.net');
//var dt = require( 'datatables.net-bs4' )( $ );
//window.DataTables.module = 'none'; // 'amd', 'commonjs', 'none'

//require('datatables.net');
//require('datatables.net-responsive')(window);
//require('datatables.net-buttons')(window);

//dt( window, $ );
//dt( window, $ );
// require('./chosen.jquery.min.js')
// require('./chart.bundle.min.js')
// require('./datatables.min.js')
// require('./select2.min.js')
// require('./tinymce/tinymce.min.js')
// require('./tinymce/tinymce.min.js')
// require('./tinymce/jquery.tinymce.min.js')
//require.context(
//  'file?name=[path][name].[ext]&context=./tinymce/skins',
//  true,
//  /.*/
//);


//var dt = require( 'datatables.net' )( window, $ );
//console.log('Hello Webpack Encore! Edit me in assets/js/app.js');

window.pw_check = function(){

	if($('#pw_check').length != 1){
		var checkliste  = '<div id="pw_check" >';
			checkliste += '<div id="pw_check_info" ></div>';
			checkliste += '<p id="pw_check_laenge" >Mindestens 8 Zeichen</p>';
			checkliste += '<p id="pw_check_kleinbuchstaben" >Kleinbuchstaben</p>';
			checkliste += '<p id="pw_check_grossbuchstaben" >Grossbuchstaben</p>';
			checkliste += '<p id="pw_check_sonderzeichen" >Sonderzeichen</p>';
			checkliste += '<p id="pw_check_zahlen" >Zahlen</p>';
		checkliste += '</div>';

		$('#password1').parent().append($(checkliste));
	}

	var val                       = document.getElementById('password1').value;
	var info                      = [];
	var laenge                    = val.length >= 8;
	var kleinbuchstaben           = val.match(/[a-zäöü]{1,}/);
	var grossbuchstaben           = val.match(/[A-ZÄÖÜ]{1,}/);
	var sonderzeichen             = val.match(/\W/);
	var zahlen                    = val.match(/\d{1,}/);
	var zahlen_oder_sonderzeichen = sonderzeichen || zahlen;
	var klasse                    = '';
	var score                     = 0;
	var scoreClass = "danger";

	score = (laenge ? 1 : 0) + (kleinbuchstaben ? 1 : 0) + (grossbuchstaben ? 1 : 0) + (sonderzeichen ? 1 : 0) + (zahlen ? 1 : 0);

	if (laenge && kleinbuchstaben && grossbuchstaben && zahlen_oder_sonderzeichen){
		info.push('Das Passwort ist sicher!');
	} else {
		if(!kleinbuchstaben || !grossbuchstaben) info.push('Das Passwort muss aus Groß- und Kleinbuchstaben bestehen.');
		if(!zahlen_oder_sonderzeichen) info.push('Das Passwort muss mindestens ein Sonderzeichen oder eine Zahl beinhalten.');
		if(!laenge) info.push('Das Passwort ist zu kurz. Es muss mindestens 8 Zeichen lang sein. (aktuell '+val.length+' Zeichen)');
	}

	if(score==3 || score==4){
		scoreClass = "warning";
	} else if(score>=5){
		scoreClass = "success";
	}

	$('#pw_check_info').html('<div>'+info.join('</div><div>')+'</div>');
	$('#pw_check_info').attr( 'class', 'alert alert-'+ scoreClass);

	$('#pw_check_laenge').attr( 'class', laenge ? 'valid' : 'invalid');
	$('#pw_check_kleinbuchstaben').attr( 'class', kleinbuchstaben ? 'valid' : 'invalid');
	$('#pw_check_grossbuchstaben').attr( 'class', grossbuchstaben ? 'valid' : 'invalid');
	$('#pw_check_sonderzeichen').attr( 'class', sonderzeichen ? 'valid' : 'invalid');
	$('#pw_check_zahlen').attr( 'class', zahlen ? 'valid' : 'invalid');

	$('button[type=submit]').prop("disabled", (score < 4 && val.length) > 0 || val.length < 8);

	const password1 = document.getElementById('password1');
	const password2 = document.getElementById('password2');

	if (password1.value === password2.value) {
		password2.setCustomValidity('');
	} else {
		password2.setCustomValidity('Die Passwörter stimmen nicht überein.');
	}

	return score >= 4;

};

$(document).ready(function($){
	if($('#password1').length && $('#password2').length){
		$('#password1,#password2').on('keyup', window.pw_check);
	}
});

window.periodCheckTTAppAuth = function(){
	setTimeout(checkTTAppAuth, 2000);
}

window.checkTTAppAuth = function(){
	$.ajax({
	  url: "/2fa_auth_check",
	}).done(function(data) {
		if(data["result"]==true){
			$("#tt-2fa-form").submit();
		} else {
			setTimeout(checkTTAppAuth, 2000);
		}
	});
}